import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Content from "../Common/Content"
import Wrapper from "../Common/Wrapper"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Img from "gatsby-image";


import { ProjectsContainer, ProjectItem, ProjectImage, ProjectImageContainer} from "./styled"


const Projects= () => {
  const data = useStaticQuery(graphql`
    query {
      phoenix: file(relativePath: { eq: "projects/phoenix.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 386) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dtc: file(relativePath: { eq: "projects/dtc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 386) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      neabpd_video_gallery: file(relativePath: { eq: "projects/neabpd-video-library.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 386) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tenant_tracker: file(relativePath: { eq: "projects/tenant_tracker.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 386) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      deepreader: file(relativePath: { eq: "projects/deepreader.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 386) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (<>
      <Wrapper>
          <Content>
              <h1>Projects</h1>
              <ProjectsContainer>
                <List class="root">
                  {/* DTC */}
                  <ListItem alignItems="flex-start">
                    <ProjectImageContainer>
                      <Img fluid={data.dtc.childImageSharp.fluid} />
                    </ProjectImageContainer>
                    <ListItemText
                      primary="Event Finder: Pariveda Design Thinking Challenge"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            class="inline"
                            color="textPrimary"
                          >
                            2021 - 2022
                          </Typography>
                          <br/>
                          {"UI prototype for local event locating mobile application, worked with a team to take a problem through the design thinking methodology, from discovery to prototyping."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  {/* NEABPD */}
                  <ListItem alignItems="flex-start">
                    <ProjectImageContainer>
                      <Img fluid={data.neabpd_video_gallery.childImageSharp.fluid} />
                    </ProjectImageContainer>
                    <ListItemText
                      primary="NEABPD Interactive Video Library"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            class="inline"
                            color="textPrimary"
                          >
                            2021 - 2022
                          </Typography>
                          <br/>
                          {"Organized the largest library of videos on BPD using Wordpress, and created extensive documentation. This will allow the team to expand the interactive library to other types of media (audios, pictures, etc.)."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />

                  {/* My Sober Companion */}
                  <ListItem alignItems="flex-start">
                    <ProjectImageContainer>
                      <Img fluid={data.phoenix.childImageSharp.fluid} />
                    </ProjectImageContainer>
                    <ListItemText
                      primary="Phoenix: My Sober Companion"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            class="inline"
                            color="textPrimary"
                          >
                            2020 - 2021
                          </Typography>
                          <br/>
                          {"A React Native mobile application offering a customized path to sobriety while rebuilding a patient’s reputation to enable their transition into society."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />

                  {/* Tenant Tracker */}
                  <ListItem alignItems="flex-start">
                    <ProjectImageContainer>
                      <Img fluid={data.tenant_tracker.childImageSharp.fluid} />
                    </ProjectImageContainer>
                    <ListItemText
                      primary="Tenant Tracker"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            class="inline"
                            color="textPrimary"
                          >
                            2017 - 2018
                          </Typography>
                          <br/>
                          {"A MEAN stack web app that improves communication between Landlords and Tenants."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />


                  {/* DeepReader */}
                  <ListItem alignItems="flex-start">
                    <ProjectImageContainer>
                      <Img fluid={data.deepreader.childImageSharp.fluid} />
                    </ProjectImageContainer>
                    <ListItemText
                      primary="DeepReader"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            class="inline"
                            color="textPrimary"
                          >
                            2017 - 2017
                          </Typography>
                          <br/>
                          {"QT application that increases comprehension of textbooks by analyzing notes for quality level."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </ProjectsContainer>

          </Content>
      </Wrapper>
  </>)
}

// const Image = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "intro/headshot.jpg" }) {
//         childImageSharp {
//           fluid(maxWidth: 386) {
//             ...GatsbyImageSharpFluid_noBase64
//           }
//         }
//       }
//     }
//   `)
//
//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

// const ProjectItems = () => {
//     const data = useStaticQuery(graphql`
//     {
//         allFile(filter: {relativeDirectory: {eq: "projects"}}) {
//           edges {
//             node {
//               childImageSharp {
//                 id
//                 fluid(maxWidth: 1000, quality: 100) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     `)
//
//     return (
//         <ProjectItem>
//           {data.allFile.edges.map((image, key) => (
//                 <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
//                     <ProjectImage
//                         key={key}
//                         fluid={image.node.childImageSharp.fluid}
//                     />
//                 </a>
//             ))}
//         </ProjectItem>
//       )
//
// }

export default Projects
