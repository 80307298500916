import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const ProjectsContainer = styled.div`
    display:flex;
    width:100%;
`

export const ProjectItem = styled.div`
    display:flex;
    flex-wrap: wrap;
    width:100%;
    margin-left: -12px;
    margin-right: -12px;

    a {
        width: 25%;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
        max-height: 150px;

        ${media.lessThan("medium")`
            width: 33.3333%;
        `}

        ${media.lessThan("small")`
            width: 50%;
        `}
    }
`

export const ProjectImage = styled(Img)`
    height: 100%;
    width: 100%;
`

export const ProjectImageContainer = styled.div`
    flex-shrink: 0;
    width: 250px;
    height: 100%;
    margin-right: 32px;

    .gatsby-image-wrapper{
        width: 100%;
        height: 100%;
        -moz-object-fit: cover;
        -webkit-object-fit: cover;
        -ms-object-fit: cover;
        object-fit: cover;
        -moz-object-position: center;
        -webkit-object-position: center;
        -ms-object-position: center;
        object-position: center;
    }


    ${media.lessThan("large")`
        width: 100%;
        height: 350px;

        img {
            width: 100%;
            height: 100%;
        }
    `}

    ${media.lessThan("medium")`
        height: 300px;
    `}

    ${media.lessThan("small")`
        height: 250px;
    `}

`
