import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import Projects from "../components/Projects"


const ProjectsPage = () => (
  <Layout>
    <Seo title="Projects" />
    <Projects/>
  </Layout>
)

export default ProjectsPage
